<template>
  <div class="container-list">
    <v-container fluid>
      <div>
        <v-row class="mb-3">
          <v-col md="6">
            <Modal button_name="Agregar Chofer Unipersonal" :modal_title="modal_title" :page_title="page_title"
              :formBtn="formBtn" :dialog="dialog" :service="service" @updateDialog="updateDialog"
              @loadList="loadList(service)" :editItem="mainItem" />
          </v-col>
          <v-col md="6">
            <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
              label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
              @input="filterSearch"></v-text-field>
          </v-col>
        </v-row>

        <Table :list="list" :header="header" :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy"
          :sortDesc="sortDesc">
          <template v-slot:rows="{ item }">
            <td class="table-cell">{{ item.name }}</td>
            <td class="table-cell">{{ item.nationality }}</td>
            <td class="table-cell">{{ item.cuit }}</td>
            <td class="table-cell">{{ item.phone }}</td>
            <td class="table-cell">{{ item.address + ' ' + item.city }}</td>
            <td class="table-cell">{{ item.max_packages }}</td>
            <td class="table-cell">{{ item.enable ? "SI" : "NO" }}</td>
            <td class="table-cell text-center">
              <img @click="viewRates(item)" :src="require('@/assets/images/bx-pencil.svg')" class="mr-2 pointer" />
              <img @click="editItem(item)" :src="require('@/assets/images/bx-pencil.svg')" class="mr-2 pointer" />
            </td>
          </template>
        </Table>

        <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
          @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

          
        <ModalRates :modal_title="'Tarifas'" :page_title="page_title"
            :dialog="dialog_rates" @updateDialog="updateDialogRates" :idriver_id="idriver_id"
            :idriver_user_id="idriver_user_id" />

        <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages" @next="loadPage(service)"
          @prev="loadPage(service)" @first="loadPage(service)" @update:modelValue="loadPage(service)"></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import Modal from "./Modal.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { idriversService } from "@/libs/ws/Super/idriversService";
import ModalRates from "./ModalRates.vue";

export default {
  name: "Choferes unipersonales",
  components: { Table, Modal, ModalRates},
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: idriversService,
      loading: false,
      header: [{ name: "Nombre", value: "name", align: "left" }, { name: "Nacionalidad", value: "nationality", align: "left" }, { name: "CUIT", value: "cuit", align: "left" }, { name: "Telefono", value: "phone", align: "left" }, { name: "Direccion", value: "address", align: "left" }, { name: "Max. Paquetes", value: "max_packages", align: "left" }, { name: "Habilitado", value: "enable", align: "left" }, { name: "Acción", align: "center" }],
      modal_title: "Chofer particular",
      button_name: "Crear",
      idriver_id: 0,
      idriver_user_id: 0,
      dialog_rates: false
    };
  },
  watch: {
    searchQuery() {
      this.filterSearch();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "CHOFERES PARTICULAR" }] });

    this.loadList(this.service);
  },
  methods: {
    updateDialogRates() {
      this.dialog_rates = false;
      this.idriver_user_id = 0;
      this.idriver_id = 0;
    },
    viewRates(item) {
      this.dialog_rates = true;
      this.idriver_id = item.id;
      this.idriver_user_id = item.user.id;
    },
  },
};
</script>
