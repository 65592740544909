<template>
    <div class="container-filters mb-4">
        <v-row>
            <v-col cols="3">
                <v-menu v-model="fromMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto" :disabled="this.mainFilters.shipping_code != '' || this.mainFilters.code != ''">
                    <template v-slot:activator="{ props }">
                        <v-label>Desde </v-label>
                        <v-text-field v-bind="props" v-model="fromDateFormatted" prepend-icon="mdi-calendar" readonly
                            density="compact" variant="outlined" persistent-hint ></v-text-field>
                    </template>
                    <v-date-picker v-model="mainFilters.from_date" @update:modelValue="closeFromMenu" hide-header></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="3">
                <v-menu v-model="toMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto" :disabled="this.mainFilters.shipping_code != '' || this.mainFilters.code != ''">
                    <template v-slot:activator="{ props }">
                        <v-label>Hasta </v-label>
                        <v-text-field v-bind="props" v-model="toDateFormatted" prepend-icon="mdi-calendar" readonly
                            density="compact" variant="outlined" persistent-hint></v-text-field>
                    </template>
                    <v-date-picker v-model="mainFilters.to_date" @update:modelValue="closeToMenu" hide-header></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="3">
                <v-label>Codigo de orden</v-label>
                <v-text-field density="compact" persistent-hint variant="outlined"
                    v-model="mainFilters.code"></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-label>Codigo de seguimiento</v-label>
                <v-text-field density="compact" persistent-hint variant="outlined"
                    v-model="mainFilters.shipping_code"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col cols="3" class="pb-0 pt-0">
                <v-label>Estado </v-label>
                <v-combobox density="compact" persistent-hint item-title="name" item-value="id" :items="states"
                    variant="outlined" autocomplete="off" v-model="mainFilters.state"></v-combobox>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="3" class="d-flex justify-content-end align-items-center pb-0 pt-0">
                <Button type="submit" class="btn-filter bg-button-register btn text-capitalize w-100 text-white"
                    rounded="xl" height="40" label="Buscar" @click="filter()"></Button>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
    components: {
        Button,
    },
    props: {
        loadList: Function,
        service: Object
    },
    data() {
        return {
            fromMenu: false,
            toMenu: false,
            states: [],
            mainFilters: {
                from_date: new Date(),
                to_date: new Date(),
                state: null,
                shipping_code: "",
                code: ""
            },

        };
    },
    computed: {
        fromDateFormatted() {
            return this.mainFilters.from_date.toISOString().slice(0, 10);
        },
        toDateFormatted() {
            return this.mainFilters.to_date.toISOString().slice(0, 10);
        },
    },
    mounted() {
        this.loadStates();
    },
    methods: {
        loadStates() {
            this.service
                .states()
                .then((response) => {
                    this.states = response.result
                })
                .catch((e) => {
                    console.log("service.load states shippings error");
                    console.log(e)
                })
        },
        getFilterParams() {
            var params = {};

            if (this.mainFilters.from_date != "" && this.mainFilters.from_date) {
                params['from_date'] = this.mainFilters.from_date.toISOString().slice(0, 10);
            }
            if (this.mainFilters.to_date != "" && this.mainFilters.to_date) {
                params['to_date'] = this.mainFilters.to_date.toISOString().slice(0, 10);
            }
            if (this.mainFilters.shipping_code != "" && this.mainFilters.shipping_code) {
                params['shipping_code'] = this.mainFilters.shipping_code;
                delete params["from_date"];
                delete params["to_date"];
            }
            if (this.mainFilters.code != "" && this.mainFilters.code) {
                params['code'] = this.mainFilters.code;
                delete params["from_date"];
                delete params["to_date"];
            }
            if (this.mainFilters.state != "" && this.mainFilters.state) {
                params['state'] = this.mainFilters.state;
            }

            return params;
        },
        filter() {
            const params = this.getFilterParams();
            this.$emit('loadList', this.service, params);
        },
        closeFromMenu() {
            this.fromMenu = false;
        },
        closeToMenu() {
            this.toMenu = false;
        },
    },
};
</script>