<template>
  <div class="skeleton-card-charts" v-show="loading">
    <v-skeleton-loader class="skeleton-card-loader" type="card"></v-skeleton-loader>
  </div>
  <v-card v-show="!loading" class="custom-card-grafico">
    <v-card-text>
      <v-row>
        <v-col cols="12" class="pb-0">
          <div>
            <v-label class="title mb-0">{{ title }}</v-label>
          </div>
          <div>
            <v-label class="subtitle">{{ subtitle }}</v-label>
          </div>
        </v-col>

        <v-col cols="12">
          <apexchart ref="apexchartRef" type="line" height="205" :options="chartOptions" :series="series" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { formatUtils } from "@/libs/utils/formatUtils";
import { dashboardService } from "@/libs/ws/Super/dashboardService";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: "Título del Gráfico",
    },
    subtitle: {
      type: String,
      default: "Subtítulo del Gráfico",
    },

  },
  data() {
    return {
      formatUtils: formatUtils,
      series: [
        {
          name: "Envios",
          data: []
        },
        {
          name: "Facturacion",
          data: []
        }
      ],
      loading: false,
      chartOptions: {
        chart: {
          type: "line",
          height: 400,
        },
        labels: [
          "Ene", "Feb", "Mar", "Abr", "May",
          "Jun", "Jul", "Ago", "Sep",
          "Oct", "Nov", "Dic"
        ],
        xaxis: {},
        yaxis: [
          {
            // Configuración del eje Y
          },
          {
            opposite: true,
            labels: {
              formatter: (value) => formatUtils.formatPrice(value), // Eje para Facturación (con signo de pesos)
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
        },
        colors: ["#26D9B1", "#073B63"],
      },
    };
  },
  mounted() {
    this.fetchDashboardMetrics();
  },
  methods: {

    fetchDashboardMetrics() {
      this.loading = true;

      dashboardService
        .metrics()
        .then((response) => {
          if (response.status) {
            const { processedShippings, totalPayments } = response.result;

            this.series[0].data = processedShippings;
            this.series[1].data = totalPayments;
          }
        })
        .catch((error) => {
          console.error("Error obteniendo métricas del dashboard:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
