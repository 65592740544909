<template>
  <div class="skeleton-card-dashboard"  v-if="loading"> 
    <v-skeleton-loader
      class="skeleton-card-loader"
      type="card"
    ></v-skeleton-loader>
  </div>
    <v-card v-else :class="customClass" class="custom-card-dashboard">
      <v-card-text>
       
        <v-row >
          <v-col cols="8">
            <div>
              <v-label :class="customClassText" class="title-card-dashboard mb-0">{{ title }}</v-label>
            </div>
            <div>
              <v-label :class="customClassText" class="subtitle-card-dashboard">{{ subtitle }}</v-label>
            </div>
            <div>
              <v-label :class="customClassText" class="subtext-card-dashboard">{{active}}/{{ total }}</v-label>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex justify-content-end align-items-end">
            <div :class="customClassIcon">
              <img :src="require('@/assets/images/sidebar/bx-desktop.svg')" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
 
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean, 
      default: false
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    customClassText: {
      type: String,
      default: "",
    },
    customClassIcon: {
      type: String,
      default: "",
    },
    active: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
};
</script>

