<template>
  <v-row align="center" justify="center" class="login">
    <v-col md="12" class="pa-0">
      <v-card :border="false" theme="dark" flat class="rounded-0 h-100 bg m-auto" max-width="450">
        <template v-slot:loader="{}"> </template>
        <v-card-title outlined class="d-flex justify-content-center">
          <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="250" class="pt-10" />
        </v-card-title>
        <v-card-text class="padding mt-5">
          <v-form>
            <h2 class="title">Iniciar Sesión</h2>
            <p class="caption">Ingresá tu usuario para poder acceder a la app</p>
            <p v-if="login_error != ''" class="text-center text-red">Los datos ingresados son incorrectos</p>
            <v-text-field label="Email" v-model="email" prepend-icon="mdi-account" variant="underlined" v-on:keyup.enter="auth" dark />
            <v-text-field
              variant="underlined"
              :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append-inner="show = !show"
              :type="show ? 'text' : 'password'"
              label="Password"
              v-model="password"
              prepend-icon="mdi-lock"
              v-on:keyup.enter="auth"
            />
          </v-form>
          <a href="/recovery/forgot-password"><p class="caption text-center">¿Olvidaste tu contraseña?</p></a>
        </v-card-text>
        <v-card-actions class="padding">
          <v-col md="12" class="pa-0">
            <Button class="bg-button-register btn text-capitalize text-white" rounded="xl" height="50" tonal block v-on:click="auth" :loading="loading" v-on:keyup.enter="auth" label="Login"></Button>
          </v-col>
        </v-card-actions>
        <v-divider></v-divider>
        <v-col md="12" class="text-center mb-4">
          <GoogleLogin :callback="callbackSocialLogin" popup-type="TOKEN">
            <button class="bg-white btn rounded-xl"><v-img class="float-left mr-2 mt-1" :src="require('@/assets/images/google.svg')" width="15" height="15" />Login con Google</button>
          </GoogleLogin>
        </v-col>

        <p class="caption text-center">
          ¿Primera vez que ingresas?
          <a href="/signup"><b>Registrate aquí</b></a>
        </p>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { authService } from "@/libs/ws/Public/authService";
import Constant from "@/constants/sections";
import { decodeCredential } from "vue3-google-login";
import Button from "@/components/Button.vue";

var CryptoJS = require("crypto-js");
export default {
  name: "App",

  components: { Button },

  data: () => ({
    login_error: "",
    email: "",
    password: "",
    show: false,
    loading: false,
  }),

  methods: {
    callbackSocialLogin(response) {
      this.loading = true;
      //console.log("Handle the response", response);
      /*
      {
        "access_token": "ya29.a0AcM612x3HJxinzZLQLRH0UMAd_OSP0Jx6VFy9_dUCNLblSdjc477bTKg2bgSkebwbvB3oxBrfUi5xJMA9w0uVImy62kNkKvkMLh-7tMUIdsVE6AZlzDSQvqFt5DiTwpn7XDO_G02VfaPi6XYJiEKyiJHDgzdbzI-FgaCgYKAU4SARISFQHGX2Mioe0D8nM0LCLzQp5eZDNUIg0169",
        "token_type": "Bearer",
        "expires_in": 3599,
        "scope": "email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
        "authuser": "0",
        "prompt": "consent"
      }
      */      

      //Verify access_token in {{url}}auth/verify/website/google POST: { access_token: XXXX }
      //ex: https://www.googleapis.com/oauth2/v3/userinfo?access_token=ya29.a0AcM612x3HJxinzZLQLRH0UMAd_OSP0Jx6VFy9_dUCNLblSdjc477bTKg2bgSkebwbvB3oxBrfUi5xJMA9w0uVImy62kNkKvkMLh-7tMUIdsVE6AZlzDSQvqFt5DiTwpn7XDO_G02VfaPi6XYJiEKyiJHDgzdbzI-FgaCgYKAU4SARISFQHGX2Mioe0D8nM0LCLzQp5eZDNUIg0169
      //console.log("response.access_token", response.access_token);
      authService.VerifySocialLogin("google",{ access_token: response.access_token }).then((response) => {
        if (!response.status) {
          this.login_error = response.msg;
        } else {
          localStorage.setItem("token", response.result.token);
          localStorage.setItem("expire_in", response.result.expire_in);
          localStorage.setItem("email", response.result.email);
          const encryptedText = CryptoJS.AES.encrypt(JSON.stringify(response.result.access), Constant.SECRET_PHRASE).toString();

          localStorage.setItem("access", encryptedText);
          localStorage.setItem("user_type", response.result.app_object.user.user_type);
          localStorage.setItem("app_object", JSON.stringify(response.result.app_object)); //Representa el id segun el tipo de usuario: Client o Employee
          this.$router.go("/");
        }
        this.loading = false;
      })
      .catch((e) => {
        console.log("error", e);
        this.loading = false;
      });
    },
    auth: function () {
      this.loading = true;
      authService
        .login(this.email, this.password)
        .then((response) => {
       
          if (!response.status) {
            this.login_error = response.msg;
          } else {
            localStorage.setItem("token", response.result.token);
            localStorage.setItem("expire_in", response.result.expire_in);
            localStorage.setItem("email", response.result.email);
            const encryptedText = CryptoJS.AES.encrypt(JSON.stringify(response.result.access), Constant.SECRET_PHRASE).toString();
            
            localStorage.setItem("access", encryptedText);
            localStorage.setItem("app_object", JSON.stringify(response.result.app_object)); //Representa el id segun el tipo de usuario: Client o Employee
            if(response.result.app_object.user != null ) {
              localStorage.setItem("user_type", response.result.app_object.user.user_type);
            } else {
              localStorage.setItem("user_type", response.result.app_object.user_type);
            }
            
            this.$router.go("/");
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    AuthProvider($social) {
      authService.socialLogin($social);
    },
  },
};
</script>
