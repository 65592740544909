import UserType from "@/constants/user_type";
import Login from "@/views/Login/Login.vue";
import DashboardSuper from "@/views/Dashboard/DashboardSuper.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import ContainerRegister from "@/views/Register/ContainerRegister.vue";
import ValidateEmail from "@/views/Register/ValidateEmail.vue";
import ContainerForgotPassword from "@/views/ForgotPassword/ContainerForgotPassword.vue";
import NewPassword from "@/views/ForgotPassword/NewPassword.vue";
import Vehicle from "@/views/Vehicle/Vehicle.vue";
import Roles from "@/views/Roles/Roles.vue";
import Employees from "@/views/Employees/Employees.vue";
import Drivers from "@/views/Drivers/Drivers.vue";
import ShippingsNotAssigned from "@/views/Shipping/ShippingsNotAssigned.vue";
import ShippingsAssigned from "@/views/Shipping/ShippingsAssigned.vue";
import MeliAccounts from "@/views/MeliAccounts/MeliAccounts.vue";
import MeliCallback from "@/views/MeliAccounts/MeliCallback.vue";
import Fee from "@/views/Fee/Fee.vue";
import Orders from "@/views/Orders/Orders.vue";
import OrderShippings from "@/views/Orders/OrderShippings.vue";
import OrdersQuotings from "@/views/OrdersQuotings/OrdersQuotings.vue";
import OrderQuotingsShippings from "@/views/OrdersQuotings/OrderQuotingsShippings.vue";
import Sellers from "@/views/Sellers/Sellers.vue";
import Logistics from "@/views/Logistics/Logistics.vue";
import IDrivers from "@/views/IDrivers/IDrivers.vue";
import MPPayment from "@/views/MPPayment/MPPayment.vue";
import ShippingAll from "@/views/Shipping/ShippingAll.vue";

/**
 * Routes public
 *
 */
const routes_public = [
  {
    path: "/auth/:provider/callback",
    component: {
      template: '<div class="auth-component"></div>',
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/signup",
    name: "Register",
    component: ContainerRegister,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/signup/validate/:user_id/:hash",
    name: "ValidateEmil",
    component: ValidateEmail,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/recovery/forgot-password",
    name: "ContainerForgotPassword",
    component: ContainerForgotPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/recovery/newpassword/:hash",
    name: "NewPassword",
    component: NewPassword,
    meta: {
      requiresAuth: false,
    },
  },
];

/**
 * Routes seller
 */
const routes_seller = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/roles",
    component: Roles,
    meta: { requiresAuth: true },
  },
  {
    path: "/collaborators",
    component: Employees,
    meta: { requiresAuth: true },
  },
  {
    path: "/shippings/not-assigned",
    component: ShippingsNotAssigned,
    meta: { requiresAuth: true },
  },
  {
    path: "/shippings/assigned",
    component: ShippingsAssigned,
    meta: { requiresAuth: true },
  },
  {
    path: "/orders",
    component: Orders,
    meta: { requiresAuth: true },
  },
  {
    path: "/orders/payment/:id/:state",
    component: Orders,
    meta: { requiresAuth: true },
  },
  {
    path: "/orders/:id",
    component: OrderShippings,
    meta: { requiresAuth: true },
  },
  {
    path: "/meli_accounts",
    component: MeliAccounts,
    meta: { requiresAuth: true },
  },
  {
    path: "/meli_accounts/callback",
    component: MeliCallback,
    meta: { requiresAuth: true },
  },
  {
    path: "/mp-payments",
    component: MPPayment,
    meta: { requiresAuth: true },
  },
];

/**
 * Routes routes_logistic
 */
const routes_logistic = [
  {
    path: "/",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/vehicle",
    component: Vehicle,
    meta: { requiresAuth: true },
  },
  {
    path: "/orders-quotings",
    component: OrdersQuotings,
    meta: { requiresAuth: true },
  },
  {
    path: "/orders-quotings/:id",
    component: OrderQuotingsShippings,
    meta: { requiresAuth: true },
  },
  {
    path: "/orders",
    component: Orders,
    meta: { requiresAuth: true },
  },
  {
    path: "/orders/:id",
    component: OrderShippings,
    meta: { requiresAuth: true },
  },
  {
    path: "/roles",
    component: Roles,
    meta: { requiresAuth: true },
  },
  {
    path: "/collaborators",
    component: Employees,
    meta: { requiresAuth: true },
  },
  {
    path: "/drivers",
    component: Drivers,
    meta: { requiresAuth: true },
  },
  {
    path: "/fee",
    component: Fee,
    meta: { requiresAuth: true },
  },
];

const routes_super = [ 
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    redirect: "/dashboard",
    meta: {requiresAuth: true}
  },
  {
    path: "/dashboard",
    component: DashboardSuper,
    meta: { requiresAuth: true },
  },
  {
    path: "/sellers",
    component: Sellers,
    meta: { requiresAuth: true },
  },
  {
    path: "/logistics",
    component: Logistics,
    meta: { requiresAuth: true },
  },
  {
    path: "/idrivers",
    component: IDrivers,
    meta: { requiresAuth: true },
  },
  {
    path: "/mp-payments",
    component: MPPayment,
    meta: { requiresAuth: true },
  },
  {
    path: "/shippings",
    component: ShippingAll,
    meta: { requiresAuth: true },
  },

]

var routes = null;

switch (localStorage.getItem("user_type")) {
  case UserType.SUPERUSER:
    routes = [...routes_super, ...routes_public];
    break;
  case UserType.SELLER:
  case UserType.EMPLOYEE_SELLER:
    routes = [...routes_seller, ...routes_public];
    break;
  case UserType.LOGISTIC:
  case UserType.EMPLOYEE_LOGISTIC:
    routes = [...routes_logistic, ...routes_public];
    break;
  default:
    routes = routes_public;
    break;
}
export default routes;
