<template>
    <div class="container-list">
      <v-container fluid>
        <div>
          <v-row class="mb-3">
            <v-col md="6">
            </v-col>
            <v-col md="6">
              <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
                label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
                @input="filterSearch"></v-text-field>
            </v-col>
          </v-row>
  
          <Filters  @loadList="loadList" :assigned="true" :shippingAll="true" :service="service"/>

          <Table :list="list" :header="header" :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy"
            :sortDesc="sortDesc">
            <template v-slot:rows="{ item }">
              <td class="table-cell">{{ item.creation_date }}</td>
              <td class="table-cell">{{ item.code }}</td>
              <td class="table-cell">{{ item.seller_order != null ? '#'+item.seller_order.id : "-"}}</td>
              <td class="table-cell">{{ item.seller_user.name }}</td>
              <td class="table-cell">{{ item.origin_address }}<br />{{ item.origin_location }} </td>
              <td class="table-cell">{{ item.destination_address }}<br />{{ item.destination_location }} </td> 
              <td class="table-cell">{{ item.driver_user != null ? item.driver_user.user_owner.name : "-" }}</td>
              <td class="table-cell">{{ item.driver_user != null ? item.driver_user.name : "-"}}</td>
              <td class="table-cell">{{ item.price_agreed != null ? item.price_agreed : "-"}}</td>
              <td class="table-cell">{{ item.state.name }}</td>
            </template>
          </Table>
  
          <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages"
            @next="loadPage(service)" @prev="loadPage(service)"
            @first="loadPage(service)"
            @update:modelValue="loadPage(service)"></v-pagination>
        </div>
      </v-container>
    </div>
  </template>
  
  <script>
  import Table from "@/components/Table.vue";
  import GenericMixin from "@/mixins/GenericMixin.vue";
  import RolMixin from "@/mixins/RolMixin.vue";
  import { shippingService } from "@/libs/ws/Seller/shippingService";
  import Filters from "./Filters.vue";
  
  export default {
    name: "Employees",
    components: { Table , Filters},
    mixins: [GenericMixin, RolMixin],
    RolMixin,
    data() {
      return {
        service: shippingService,
        loading: false,
        header: [
          { name: "Fecha", value: "creation_date", align: "left" },
          { name: "Codigo de seguimiento", value: "code", align: "left" },
          { name: "Nº Orden", value: "seller_order.code", align: "left" },
          { name: "Vendedor", value: "seller_user.name", align: "left" },
          { name: "Origen", value: "", align: "left" },
          { name: "Destino", value: "", align: "left" },
          { name: "Transporte", value: "driver_user.user_owner.name", align:"left"},
          { name: "Chofer", value: "driver_user.name", align: "left" },
          { name: "Precio", value: "price_agreed", align: "left" },
          { name: "Estado", value: "state.name", align: "left" },

        ],
        modal_title: "Envios",
        button_name: "Crear",
        params: {
          from_date: new Date().toISOString().slice(0, 10),
          to_date: new Date().toISOString().slice(0, 10),   
        }
      };
    },
    watch: {
      searchQuery() {
        this.filterSearch();
      },
    },
    mounted() {
      this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "ENVIOS" }] });
  
      this.loadList(this.service, this.params);
    },
    methods: {
   
    },
  };
  </script>
  