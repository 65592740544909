<template>
  <div class="container-list">
    <v-container fluid>
      <div>
        <v-row class="mb-3">
          <v-col md="6">

          </v-col>
          <v-col md="6">
            <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
              label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
              @input="filterSearch"></v-text-field>
          </v-col>
        </v-row>

        <Filters @loadList="loadList" :service="service" />

        <Table :list="list" :header="header" :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy"
          :sortDesc="sortDesc">
          <template v-slot:rows="{ item }">
            <td class="table-cell">#{{ item.code }}</td>
            <td class="table-cell">{{ item.creation_date }}</td>
            <td class="table-cell">{{ item.shippings.length }}</td>
            <td class="table-cell">{{ item.state.name }}</td>
            <td class="table-cell">{{ item.sub_state ? item.sub_state.name : " - " }}</td>

            <td class="table-cell text-center">
              <v-tooltip location="bottom" text="Detalle de orden">
                <template v-slot:activator="{ props }">
                  <img v-bind="props" @click="viewItemPage(item)" :src="require('@/assets/images/view.svg')"
                    class="mr-2 pointer" />

                </template>
              </v-tooltip>

              <v-tooltip location="bottom" text="Cotizar">
                <template v-slot:activator="{ props }" v-if="isViewQuotingOrder(item)">
                  <img v-bind="props" @click="editItem(item)" :src="require('@/assets/images/quoting.svg')"
                    class="mr-2 pointer" />
                </template>
              </v-tooltip>

              <v-tooltip location="bottom" text="Pagar">
                <template v-slot:activator="{ props }" v-if="isViewPay(item)">
                  <img v-bind="props" @click="handlePayOrder(item)" :src="require('@/assets/images/paid.svg')"
                    class="mr-2 pointer" />
                </template>
              </v-tooltip>

              <v-tooltip location="bottom" text="Eliminar">
                <template v-slot:activator="{ props }">
                  <img v-if="handleDeleteOrders(item)" v-bind="props" @click="deleteItem(item)"
                    :src="require('@/assets/images/bx-trash.svg')" class="mr-2 pointer" />
                </template>
              </v-tooltip>
            </td>
          </template>
        </Table>

        <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
          @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

        <Modal :modal_title="modal_title" :page_title="page_title" :formBtn="formBtn" :dialog="dialog"
          :service="service" @updateDialog="updateDialog" @loadList="loadList(service)" :editItem="mainItem" />

        <ModalPay :modal_title="modal_title" :page_title="page_title" :formBtn="formBtn" :dialog="dialog_pay"
          :service="service" @updateDialog="updateDialogPay" @loadList="loadList(service)" :editItem="mainItem"
          :paymentStatus="paymentStatus" :statusTitle="statusTitle" :statusMessage="statusMessage"
          :step_pay="step_pay"
          :order_id="order_id"
          />

        <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages" @next="loadPage(service)"
          @prev="loadPage(service)" @first="loadPage(service)" @update:modelValue="loadPage(service)"></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { ordersService } from "@/libs/ws/Seller/ordersService";
import OrderState from "@/constants/order_state";
import OrderQuotingsState from "@/constants/order_quotings_state";
import Filters from "./Filters.vue";
import Modal from "./ModalQuotation.vue";
import ModalPay from "./ModalPay.vue";

export default {
  name: "Orders",
  components: { Table, Filters, Modal, ModalPay },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: ordersService,
      loading: false,
      header: [
        { name: "Nº Orden", value: "code", align: "left" },
        { name: "Fecha de creacion", value: "creation_date", align: "left" },
        { name: "Cantidad de envios", value: "", align: "left" },
        { name: "Estado", value: "state.name", align: "left" },
        { name: "Sub Estado", value: "sub_state.name", align: "left" },

        { name: "Acción", align: "center" },
      ],
      modal_title: "Orden",
      button_name: "Crear",
      params: {
        from_date: new Date().toISOString().slice(0, 10),
        to_date: new Date().toISOString().slice(0, 10)
      },
      dialog_quotation: false,
      order_id: 0,
      dialog_pay: false,
      paymentStatus: '',
      statusTitle: '',
      statusMessage: 'Hubo un problema al procesar tu pago.',
      step_pay: 1,
      loading_order_by_id: false
    };
  },
  watch: {
    searchQuery() {
      this.filterSearch();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: 'ORDENES ' }] });

    this.loadList(this.service, this.params);
  },
  created() {
    const { id, state } = this.$route.params;

    if (id && state) {
      this.step_pay = 2;
      this.order_id = id;
      this.fetchOrder(parseInt(id), state);
      this.dialog_pay = true;
    }
  },
  methods: {
    fetchOrder(id, state) {
      this.loading_order_by_id = true

      this.service
        .getByCode(id)
        .then((response) => {
          if(response.status) {
            this.editedIndex = this.list.indexOf(response.result);
            this.mainItem = Object.assign({}, response.result);
            this.setPaymentStatus(state);

          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.statusMessage = response.msg;
              this.setPaymentStatus("error");
            } else {
              this.statusMessage = response.msg;
              this.setPaymentStatus("error");
            }
          }
        })
        .catch((e) => {
          console.log("service order shippings codes error");
          this.setPaymentStatus("error");

        })
        .finally(() => {
          this.loading_order_by_id = false;

        });
    },
    setPaymentStatus(state) {
      switch (state) {
        case 'success':
          this.paymentStatus = 'success';
          this.statusTitle = '¡Listo! Tu pago se acreditó';
          this.statusMessage = 'Tus envíos serán entregados por ' + this.mainItem.order_quoting.contact_data.business_name;
          break;
        case 'failure':
          this.paymentStatus = 'failure';
          this.statusTitle = 'Hubo un error al procesar tu pago';
          this.statusMessage = 'Intenta el pago nuevamente con otro medio de pago';
          break;
        case 'pending':
          this.paymentStatus = 'pending';
          this.statusTitle = 'Tu pago esta pendiente de aprobación';
          this.statusMessage = 'Estamos procesando tu pago </br> en unos minutos veras el resultado en el estado de la orden';
          break;
        default:
          this.paymentStatus = 'error';
          this.statusTitle = 'Estado desconocido';
      }
    },
    isViewPay(item) {
      if (item.state.id == OrderState.STATE_ACEPTED &&
        item.sub_state.id == OrderState.SUBSTATE_PENDIENTE_PAGO &&
        item.order_quoting.state.id == OrderQuotingsState.STATE_ACCEPTED) {
        return true;
      }
      return false;
    },
    handlePayOrder(item) {
      this.editedIndex = this.list.indexOf(item);
      this.mainItem = Object.assign({}, item);
      this.dialog_pay = true;
    },
    updateDialogPay(value) {
      if (!value) {
        this.close();
        this.$router.push("/orders")
      }
      this.dialog_pay = value;
    },
    isViewQuotingOrder(item) {
      if (item.state.id == OrderState.STATE_PENDING ||
        (item.state.id == OrderState.STATE_QUOTING &&
          (item.sub_state.id == OrderState.SUBSTATE_CANCELADA ||
            item.sub_state.id == OrderState.SUBSTATE_VENCIDA ||
            item.sub_state.id == OrderState.SUBSTATE_RECHAZADA))) {
        return true;
      }

      return false;
    },
    handleModalQuotation(item) {
      this.order_id = item.id;
      this.dialog_quotation = true;
    },

    viewItemPage(item) {
      this.$router.push(`/orders/${item.code}`);
    },
    handleDeleteOrders(item) {
      if (item.state.id === OrderState.STATE_PENDING) {
        return true

      }
      return false
    }
  },
};
</script>
