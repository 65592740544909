<template>
    <div class="container-list">
        <v-container fluid>
            <div>
                <v-row>
                    <v-col class="">
                        <v-row class="mt-1 box-border align-items-center container-order-shippings">
                            <v-col>
                                <v-col class="v-col text-center text-button pb-0">
                                    <v-label class="order-title">
                                        Orden de Envio
                                    </v-label> </v-col>
                                <v-col class="text-center pt-0 order-text">
                                    <v-label class="order-text">
                                        #{{ orderShipping?.code }}
                                    </v-label>
                                </v-col>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col>
                                <v-col class="v-col text-center text-button pb-0">
                                    <v-label class="order-title">
                                        Cliente
                                    </v-label>
                                </v-col>
                                <v-col class="text-center pt-0 order-text">
                                    <v-label class="order-text">
                                        {{ orderShipping?.seller_user?.role?.name }}
                                    </v-label>

                                </v-col>
                            </v-col>

                            <v-divider vertical></v-divider>
                            <v-col>
                                <v-col class="v-col text-center text-button pb-0">
                                    <v-label class="order-title">
                                        Fecha Creacion
                                    </v-label>

                                </v-col>
                                <v-col class="text-center pt-0 order-text">
                                    <v-label class="order-text">
                                        {{ orderShipping.creation_date }}
                                    </v-label>
                                </v-col>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col>
                                <v-col class="v-col text-center text-button pb-0">
                                    <v-label class="order-title">
                                        Estado
                                    </v-label>
                                </v-col>
                                <v-col class="text-center pt-0 order-text">
                                    <v-label class="order-text">
                                        {{ orderShipping?.state?.name }}
                                    </v-label>
                                </v-col>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row class="mt-4 pt-4">
                    <v-col cols="6" class="px-0 mx-0 mt-0 pt-0 container-shippings" style="background: #FFF;">
                        <v-expansion-panels>

                            <v-expansion-panel v-for="(shipping, index) in orderShipping.shippings" :key="index"
                                class="container-expansion-shippings">
                                <v-expansion-panel-title>
                                    <v-row>
                                        <v-col cols="10">
                                            <div class="d-flex align-items-center">
                                                <div class="image-container"
                                                    :style="{ backgroundImage: 'url(' + getIcon(shipping).icon + ')' }">
                                                    <span class="number">{{ index + 1 }}</span>
                                                </div>
                                                <v-label class="order-title mb-0 ms-2">

                                                    {{ shipping.code }}
                                                </v-label>



                                            </div>
                                            <div>

                                                <v-label class="order-text-state mt-1">
                                                    {{ shipping.state.name }}

                                                </v-label>
                                            </div>

                                        </v-col>
                                        <v-col cols="2"
                                        class="d-flex justify-content-center align-items-center text-align-center">
                                            <v-tooltip location="bottom" text="Eliminar">
                                                <template v-slot:activator="{ props }">
                                                    <div v-if="handleDeleteShipping(orderShipping)"
                                                        @click.stop="deleteItem(shipping)" style="z-index: 1001;">
                                                        <img v-bind="props" :src="require('@/assets/images/bx-trash.svg')"
                                                            class="ml-2 pointer" />
                                                    </div>
                                                </template>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>


                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <v-row>
                                        <v-col cols="6">
                                            <div>
                                                <v-label class="order-title">
                                                    Recepción
                                                </v-label>
                                            </div>
                                            <div>

                                                <v-label class="order-text">
                                                    {{ shipping.destination_receiver_name }}
                                                </v-label>
                                            </div>
                                            <div>
                                                <v-label class="order-title">
                                                    Dirección
                                                </v-label>
                                            </div>
                                            <div>
                                                <v-label class="order-text">
                                                    {{ shipping.destination_address }}
                                                </v-label>
                                            </div>

                                            <div>
                                                <v-label class="order-title">
                                                    Referencia
                                                </v-label>
                                            </div>
                                            <div>
                                                <v-label class="order-text">
                                                    {{ shipping.external_reference }}
                                                </v-label>
                                            </div>
                                        </v-col>
                                        <v-col cols="6">
                                            <div>
                                                <v-label class="order-title">
                                                    Teléfono
                                                </v-label>
                                            </div>
                                            <div>
                                                <v-label class="order-text">
                                                    {{ shipping.destination_telephone }}
                                                </v-label>
                                            </div>
                                            <div>
                                                <v-label class="order-title">
                                                    Localidad
                                                </v-label>
                                            </div>
                                            <div>
                                                <v-label class="order-text">
                                                    {{ shipping.destination_location }}
                                                </v-label>
                                            </div>

                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>

                    </v-col>
                    <v-col cols="6" class="ms-0 ps-0 mt-0 pt-0">
                        <GMapMap :center="mapCenter" :zoom="16" :options="mapOptions" style="width: 100%; height: 70vh">
                            <GMapMarker v-for="(shipping, index) in orderShipping.shippings" :key="index"
                                :position="{ lat: parseFloat(shipping.destination_lat), lng: parseFloat(shipping.destination_lng) }"
                                :icon="getIcon(shipping).icon" :label="{
                                            text: `${index + 1}`,
                                            color: 'white',
                                            className: 'marker-position'
                                        }" />
                        </GMapMap>
                    </v-col>
                </v-row>
                <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
                    @deleteItemConfirm="deleteShipping" :loading="loadingDeleteShipping" />

            </div>
        </v-container>
    </div>
</template>

<script>
import Table from "@/components/Table.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";

import { ordersService } from "@/libs/ws/Seller/ordersService";
import ShippingState from "@/constants/shipping_state";
import OrderState from "@/constants/order_state";


export default {
    name: "Orders",
    components: {},
    props: {
        editItem: Object
    },
    mixins: [GenericMixin, RolMixin],
    RolMixin,
    data() {
        return {
            service: ordersService,
            loading: false,
            loadingDeleteShipping: false,
            orderShipping: {},
            shippings: [],
            mapCenter: { lat: parseFloat(-34.6786464000000000), lng: parseFloat(-58.4887551000000000) },
            mapOptions: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                disableDefaultUi: true,
            },

        };
    },
    watch: {
        searchQuery() {
            this.filterSearch();
        },
    },
    mounted() {
        this.$store.commit("setPageTitle", { pageTitle: [{ icon: 'arrow-left', page: 'ORDENES ' }, { icon: '', page: 'ENVIOS DE ORDENES' }] });

        this.loadOrderShippings();
    },
    methods: {
        loadOrderShippings() {
            this.loading = true

            ordersService
                .getByCode(this.$route.params.id)
                .then((response) => {
                    this.orderShipping = response.result
                    this.shippings = response.result.shippings
                    this.mapCenter = {lat: parseFloat(this.shippings[0].destination_lat), lng: parseFloat(this.shippings[0].destination_lng)}
                })
                .catch((e) => {
                    console.log("service order shippings codes error");
                })
                .finally(() => {
                    this.loading = false;
                });

        },
        getIcon(shipping) {
            switch (shipping.state.id) {
                case ShippingState.STATE_PENDING:
                    return { icon: require('@/assets/images/geo/geo/blue-grey.svg'), label: 1 };
                case ShippingState.STATE_ON_THE_WAY:
                    return { icon: require('@/assets/images/geo/geo/azul.svg'), label: 1 };
                case ShippingState.STATE_DELIVERED:
                    return { icon: require('@/assets/images/geo/geo/verde.svg'), label: 1 };
                case ShippingState.STATE_NOT_DELIVERED:
                    return { icon: require('@/assets/images/geo/geo/amarillo.svg'), label: 1 };
                case ShippingState.STATE_RETURNED:
                    return { icon: require('@/assets/images/geo/geo/naranja.svg'), label: 1 };
                case ShippingState.STATE_INCOMPLETE:
                    return { icon: require('@/assets/images/geo/geo/gris.svg'), label: 1 };
                default:
                    return null;
            }
        },
        handleDeleteShipping(shipping) {
            if (shipping.state.id === OrderState.STATE_PENDING) {
                return true
            }

            return false
        },
        deleteShipping() {
            this.loadingDeleteShipping = true;
            const body = {
                id: this.orderShipping.id,
                shippings_arr: [{
                    id: this.deleteId
                }]
            }
            this.service.removeShipping(body)
                .then((response) => {
                    if (response.status) {

                        this.toast.success("Delete OK.");

                        this.loadOrderShippings();
                    } else {
                        if (Object.prototype.hasOwnProperty.call(response, "message")) {
                            this.toast.error(response.message);
                        } else {
                            this.toast.error(response.msg);
                        }
                    }

                })
                .catch((response) => {

                    this.toast.error(response.msg);

                    console.log("service delete shipping in orders load error")
                    console.log(response.msg)
                })
                .finally(() => {
                    this.dialog_delete = false;
                    this.loadingDeleteShipping = false;

                })
        }
    },
};
</script>
