<template>
  <div class="container-list">
    <v-container fluid>
      <div>
        <v-row class="mb-3">
          <v-col md="6"></v-col>
          <v-col md="6">
            <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
              label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
              @input="filterSearch"></v-text-field>
          </v-col>
        </v-row>

        <Filters  @loadList="loadList" :service="service"/>

        <Table :list="list" :header="userType === ConstantUserType.SUPERUSER ? headerSuper : headerSeller"
          :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy" :sortDesc="sortDesc">
          <template v-slot:rows="{ item }">
            <td class="table-cell">{{ item.payment_id }}</td>
            <td class="table-cell">{{ item.date_created }}</td>
            <td class="table-cell">{{ item.date_approved }}</td>
            <td v-if="userType === ConstantUserType.SUPERUSER" class="table-cell">{{ item.seller_user.name }}</td>
            <td v-if="userType === ConstantUserType.SELLER || userType === ConstantUserType.EMPLOYEE_SELLER"
              class="table-cell">#{{ item.order_quoting.id}}</td>
              <td class="table-cell">{{ item.order_quoting.seller_order.order_quoting.contact_data.business_name }}</td>
              <td class="table-cell">{{ item.payer_type }} - {{ item.payer_doc }}</td>
            <td class="table-cell">{{ formatUtils.formatPrice(item.total_paid_amount) }}</td>
            <td class="table-cell">{{ translateStatus(item.last_status) }}</td>
          </template>
        </Table>

        <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages" @next="loadPage(service)"
          @prev="loadPage(service)" @first="loadPage(service)" @update:modelValue="loadPage(service)"></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { mpPaymentService } from "@/libs/ws/Super/mpPaymentService";
import { formatUtils } from "@/libs/utils/formatUtils";
import ConstantUserType from "@/constants/user_type";
import Filters from './Filters.vue';

export default {
  name: "Pagos",
  components: { Table, Filters },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      service: mpPaymentService,
      formatUtils: formatUtils,
      loading: false,
      userType: "",
      ConstantUserType: ConstantUserType,
      headerSuper: [
        { name: "Comprobante N°", value: "payment_id", align: "left" },
        { name: "Fecha Creacion", value: "date_created", align: "left" },
        { name: "Fecha Aprobado", value: "date_approved", align: "left" },
        { name: "Vendedor", value: "seller_user.name", align: "left" },
        { name: "Transporte", value: "order_quoting.contact_data.business_name", align: "left" },
        { name: "Pagador", value: "", align: "left" },
        { name: "Importe", value: "total_paid_amount", align: "left" },
        { name: "Estado", value: "last_status", align: "left" }
      ],
      headerSeller: [
        { name: "Comprobante N°", value: "payment_id", align: "left" },
        { name: "Fecha Creacion", value: "date_created", align: "left" },
        { name: "Fecha Aprobado", value: "date_approved", align: "left" },
        { name: "Orden", value: "order_quoting.id", align: "left" },
        { name: "Transporte", value: "order_quoting.seller_order.order_quoting.contact_data.business_name", align: "left" },
        { name: "Pagador", value: "", align: "left" },
        { name: "Importe", value: "total_paid_amount", align: "left" },
        { name: "Estado", value: "last_status", align: "left" }
      ]
    };
  },
  computed: {
    header() {
      return this.getHeaders();
    }
  },
  watch: {
    searchQuery() {
      this.filterSearch();
    },
  },
  mounted() {
    this.userType = localStorage.getItem('user_type');
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "PAGOS" }] });
    this.loadList(this.service);
  },
  methods: {

    translateStatus(status) {
      const translations = {
        pending: 'Pendiente',
        approved: 'Aprobado',
        inprocess: 'En proceso',
        inmediation: 'En mediación',
        rejected: 'Rechazado',
        cancelled: 'Cancelado',
        refunded: 'Reembolsado',
        chargedback: 'Contracargo'
      };
      return translations[status] || status;
    }
  }
};
</script>
