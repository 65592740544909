<template>
   <div class="skeleton-card-dashboard"  v-if="loading"> 
    <v-skeleton-loader
      class="skeleton-card-loader"
      type="card"
    ></v-skeleton-loader>
  </div>
  <v-card v-else :class="customClass" class="custom-card-dashboard">
    <v-card-text>
      <v-row >
        <v-col cols="8">
          <div>
            <v-label :class="customClassText" class="title-card-dashboard mb-0">{{ title }}</v-label>
          </div>
          <div>
            <v-label :class="customClassText" class="subtitle-card-dashboard">{{ subtitle }}</v-label>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex justify-content-end ">
          <font-awesome-icon
            v-if="!payment"
            :icon="['fas', 'paper-plane']"
            class="icon-shipping mr-3 pt-2"
          />
          <font-awesome-icon
            v-if="payment"
            :icon="['fas', 'dollar-sign']"
            class="icon-payment mr-3 pt-2"
          />
        </v-col>
        <v-col cols="12" class="text-end pt-0 pb-0">
          <v-label :class="customClassText" class="price-card-dashboard mr-3">{{ price }}</v-label>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: "",
    },
    customClassText: {
      type: String,
      default: "",
    },
    payment: {
      type: Boolean,
      default: false
    }
  },
};
</script>

