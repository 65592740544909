<template>
  <div class="container-list">
    <v-container fluid>
      <div>
        <v-row class="mb-3">
          <v-col md="6">
            <Modal button_name="Crear Envio" :modal_title="modal_title"  :formBtn="formBtn"
              :dialog="dialog" :service="service" @updateDialog="updateDialog" @loadList="loadListNotAssinged"
              :editItem="mainItem" :loading="loading" />
          </v-col>
          <v-col md="6">
            <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
              label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
              @input="filterSearch"></v-text-field>
          </v-col>
        </v-row>

        <Filters @loadList="loadList" :assigned="false" :service="service" />

        <Table :list="list" :header="header" :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy"
          :sortDesc="sortDesc" @handleSelectedAllItems="handleSelectedAllItems" :selectedAllParent="selectedAllParent">
          <template v-slot:rows="{ item }">
            <td class="table-cell" :style="{maxWidth: '50px'}">
              <v-checkbox v-if=handleViewCheckbox(item) class="check-table" v-model="selected" :value="item"
                @change="handleSelectedItem()"></v-checkbox>
            </td>
            <td class="table-cell">{{ item.creation_date }}</td>
            <td class="table-cell">{{ item.code }}</td>
            <td class="table-cell">{{ item.destination_address }} <br /> {{ item.destination_location }}</td>
            <td class="table-cell" :style="{maxWidth: '80px'}">{{ item.bultos }}</td>
            <td class="table-cell" :style="{maxWidth: '80px'}">{{ item.kg }}</td>
            <td class="table-cell">{{ item.state.name }}</td>

            <td class="table-cell text-center">
              <v-tooltip location="bottom" text="Editar">
                <template v-slot:activator="{ props }">
                  <img v-bind="props" @click="editItem(item)" :src="require('@/assets/images/bx-pencil.svg')" class="mr-2 pointer" />
                </template>
              </v-tooltip>
              <v-tooltip location="bottom" text="Eliminar">
                <template v-slot:activator="{ props }">
                  <img v-bind="props" @click="deleteItem(item)" :src="require('@/assets/images/bx-trash.svg')" class="ml-2 pointer" />
                </template>
              </v-tooltip>
            </td>
          </template>
        </Table>

        <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
          @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

        <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages"
          @next="loadPage(service, { no_order: 1 })" @prev="loadPage(service, { no_order: 1 })"
          @first="loadPage(service, { no_order: 1 })"
          @update:modelValue="loadPage(service, { no_order: 1 })"></v-pagination>
      </div>


    </v-container>


  </div>
  <v-row class="selected-items-bar" v-if="selected.length > 0">
    <v-col md="6">
      <span class="text-white mb-0 ms-4 title-main">{{ handleCountShippingSelected() }} envíos seleccionados</span>
    </v-col>
    <v-col md="6" class="text-right">
      <v-btn @click="clearSelection" class="me-2">Limpiar selección</v-btn>
      <v-menu location="top">
        <template v-slot:activator="{ props }">
          <v-btn color="white" dark v-bind="props">
            Generar Orden
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="( item, index ) in itemsGenerateOrder " :key="index">
            <v-list-item-title @click="item.function()">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <ModalOrders :open="modalOrders" title="Ordenes" @cancel="modalOrders = false" nameButtonNo="Cancelar"
      nameButtonYes="Agregar a orden" :selected="selected" @loadList="loadList" />
  </v-row>
</template>

<script>
import Table from "@/components/Table.vue";
import ModalOrders from "./ModalOrders.vue";
import Filters from "./Filters.vue";
import Modal from "./Modal.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { shippingService } from "@/libs/ws/Seller/shippingService";
import { ordersService } from "@/libs/ws/Seller/ordersService";
import ShippingState from "@/constants/shipping_state";

export default {
  name: "ShippingsNotAssigned",
  components: { Table, Modal, ModalOrders, Filters },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: shippingService,
      loading: false,
      modalOrders: false,
      header: [
        { name: "", value: "", align: "left", checkbox: true, maxWidth: "50px" },
        { name: "Fecha", value: "creation_date", align: "left" },
        { name: "Codigo de seguimiento", value: "code", align: "left" },
        { name: "Destino", value: "", align: "left" },
        { name: "Bultos", value: "bultos", align: "left",maxWidth: "80px" },
        { name: "Kg", value: "kg", align: "left",maxWidth: "80px" },
        { name: "Estado", value: "state.name", align: "left" },
        { name: "Acción", align: "center" },
      ],
      modal_title: "Envios",
      button_name: "Crear ",
      showFilters: false,
      itemsGenerateOrder: [
        { title: 'Crear orden', function: this.createOrder },
        { title: 'Agregar a una orden existente', function: this.handleModalOrders }
      ],
   
      params: {
        from_date: new Date().toISOString().slice(0, 10),
        to_date: new Date().toISOString().slice(0, 10),
        no_order: 1
      }

    };
  },
  watch: {
    searchQuery() {
      this.filterSearch();
    },


  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "ENVIOS SIN ASIGNAR" }] });
    this.loadList(this.service, this.params);
  },
  methods: {
    clearSelection() {
      this.selected = [];
    },
    handleModalOrders() {
      this.modalOrders = true;
    },
    loadListNotAssinged() {
      this.loadList(this.service, {no_order: 1});
    },
    createOrder() {
      const body = {
        id: 0,
        shippings_arr: this.selected.filter((item) => item.state.id != ShippingState.STATE_INCOMPLETE).map(item => ({ id: item.id }))
      }

      ordersService
        .create(body)
        .then((response) => {
          if (response.status) {
            this.toast.success("La Orden se creó correctamente.");
            this.loadList(this.service, { no_order: 1 });

          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((response) => {
          this.toast.error(response.msg);
        })
        .finally(() => {
        });

    },
    handleViewCheckbox(order) {
      if (order.state.id === ShippingState.STATE_INCOMPLETE) {
        return false
      }

      return true
    },
    handleCountShippingSelected() {
      const selected = this.selected.filter(select => select.state.id != ShippingState.STATE_INCOMPLETE);

      return selected.length;
    }
  },
};
</script>
