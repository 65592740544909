import { wsService } from "../wsService";

const section = "mp-payments";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

export const mpPaymentService = {
  section,
  filter,
};
