import { wsService } from "../wsService";

const section = "dashboard";

function sellers(param = { enable: true }) {
  return wsService.make_post_request(section + "/sellers", param);
}

function logistics(param = {}) {
  return wsService.make_post_request(section + "/logistics", param);
}

function independent_drivers(param = {}) {
  return wsService.make_post_request(section + "/independent-drivers", param);
}

function payments(param = {}) {
  return wsService.make_post_request(section + "/payments", param);
}

function shippings(param = {}) {
  return wsService.make_post_request(section + "/shippings", param);
}

function top_seller(param = {}) {
  return wsService.make_post_request(section + "/top-sellers", param);
}

function metrics(param = {}) {
  return wsService.make_post_request(section + "/metrics", param);
}

export const dashboardService = {
  section,
  sellers,
  logistics,
  independent_drivers,
  payments,
  shippings,
  top_seller,
  metrics
};
