<template>
  <div class="container-dashboard">

    <v-row>
      <v-col cols="8">
        <v-row>
          <v-col cols="6">
            <CardDashboard customClass="custom-card-facturacion" customClassText="custom-card-text-facturacion"
              title="Facturación" subtitle="Mensual" :price="`${formatUtils.formatPrice(total_payment)}`"
              :payment="true" :loading="loading_total_payment" />

          </v-col>
          <v-col cols="6">
            <CardDashboard customClass="custom-card-envios" customClassText="custom-card-text-envios"
              title="Envíos procesados" subtitle="Mensual" :price="total_shipping" :payment="false"
              :loading="loading_total_shipping" />
          </v-col>
          <v-col cols="12">
              <CardCharts title="Facturacion vs Envios" subtitle="Anual" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <TopSellers title="Top 5 vendedores" subtitle="Mensual" :sellers="listSellers" :loading="loading_top_sellers" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <CardAmounts customClass="custom-card-envios" customClassText="custom-card-text-envios"
          title="Cantidad de vendedores" subtitle="Activos/Totales" :active="seller_active" :total="seller_total"
          customClassIcon="container-icon-sellers" :loading="loading_sellers" />
      </v-col>
      <v-col cols="4">
        <CardAmounts customClass="custom-card-logistics" customClassText="custom-card-text-envios"
          title="Cantidad de transportistas" subtitle="Activos/Totales" :active="logistic_active"
          :total="logistic_total" customClassIcon="container-icon-logistics" :loading="loading_logistics" />
      </v-col>
      <v-col cols="4">
        <CardAmounts customClass="custom-card-envios" customClassText="custom-card-text-envios"
          title="Cantidad de choferes unipersonales" subtitle="Activos/Totales" :active="independent_driver_active"
          :total="independent_driver_total" customClassIcon="container-icon-sellers"
          :loading="loading_independent_drivers" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { dashboardService } from "@/libs/ws/Super/dashboardService";
import CardDashboard from "@/components/CardDashboard.vue";
import CardAmounts from "@/components/CardAmounts.vue";
import TopSellers from "@/components/TopSellers.vue";
import { formatUtils } from "@/libs/utils/formatUtils";
import CardCharts from "@/components/CardCharts.vue";
export default {
  name: "Dashboard",
  components: { CardDashboard, CardAmounts, TopSellers, CardCharts },
  data() {
    return {
      formatUtils: formatUtils,
      page_title: "Dashboard",
      listSellers: [],
      seller_active: 0,
      seller_total: 0,
      logistic_active: 0,
      logistic_total: 0,
      independent_driver_active: 0,
      independent_driver_total: 0,
      total_payment: 0,
      total_shipping: 0,
      loading_total_payment: false,
      loading_total_shipping: false,
      loading_sellers: false,
      loading_logistics: false,
      loading_independent_drivers: false,
      loading_top_sellers: false,
      loading_chart: false,
    };
  },
  mounted() {
    this.getCountSellers();
    this.getCountLogistics();
    this.getCountIndependentDrivers();
    this.getCountPayments();
    this.getProcessedShippings();
    this.getTopSellersByProcessedShippings();
  },
  methods: {
    getCountSellers() {
      this.loading_sellers = true;
      dashboardService
        .sellers()
        .then((response) => {
          this.seller_active = response.result.actives;
          this.seller_total = response.result.total;
        })
        .catch(e => console.log("error get count sellers", e))
        .finally(() => {
          this.loading_sellers = false;
        })
    },
    getCountLogistics() {
      this.loading_logistics = true;
      dashboardService
        .logistics()
        .then((response) => {
          this.logistic_active = response.result.actives;
          this.logistic_total = response.result.total;
        })
        .catch(e => console.log("e get count logistics", e))
        .finally(() => {
          this.loading_logistics = false
        })
    },
    getCountIndependentDrivers() {
      this.loading_independent_drivers = true;
      dashboardService
        .independent_drivers()
        .then((response) => {
          this.independent_driver_active = response.result.actives;
          this.independent_driver_total = response.result.total;
        })
        .catch(e => console.log("e get count independent driver", e))
        .finally(() => {
          this.loading_independent_drivers = false;
        })
    },
    getCountPayments() {
      this.loading_total_payment = true;
      dashboardService
        .payments()
        .then((response) => {
          this.total_payment = response.result.total;
        })
        .catch(e => console.log("e get count payment", e))
        .finally(() => {
          this.loading_total_payment = false;
        })
    },
    getProcessedShippings() {
      this.loading_total_shipping = true;
      dashboardService
        .shippings()
        .then((response) => {
          this.total_shipping = response.result.shippings;
        })
        .catch(e => console.log("e get processed shipping", e))
        .finally(() => {
          this.loading_total_shipping = false;
        })
    },
    getTopSellersByProcessedShippings() {
      this.loading_top_sellers = true;
      dashboardService
        .top_seller()
        .then((response) => {
          this.listSellers = response.result;
        })
        .catch(e => console.log("e get top sellers", e))
        .finally(() => {
          this.loading_top_sellers = false;
        })
    },
  },
};
</script>
