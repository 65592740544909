<template>
  <div class="container-list">
    <v-container fluid>
      <div>
        <v-row class="mb-3">
          <v-col md="6">
            <Modal button_name="Agregar Logistica" :modal_title="modal_title" :page_title="page_title" :formBtn="formBtn"
              :dialog="dialog" :service="service" @updateDialog="updateDialog" @loadList="loadList(service)"
              :editItem="mainItem" />
          </v-col>
          <v-col md="6">
            <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
              label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
              @input="filterSearch"></v-text-field>
          </v-col>
        </v-row>

        <Table :list="list" :header="header" :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy"
          :sortDesc="sortDesc">
          <template v-slot:rows="{ item }">
            <td class="table-cell">{{ item.name }}</td>
            <td class="table-cell">{{ item.business_name }}</td>
            <td class="table-cell">{{ item.cuit }}</td>
            <td class="table-cell">{{ item.phone }}</td>
            <td class="table-cell">{{ item.address + ' ' + item.city }}</td>
            <td class="table-cell">{{ item.max_packages}}</td>
            <td class="table-cell">{{ item.enable ? "SI" : "NO"}}</td>

            <td class="table-cell text-center">
              <img @click="viewZones(item)" :src="require('@/assets/images/bx-pencil.svg')" class="mr-2 pointer" />
              <img @click="editItem(item)" :src="require('@/assets/images/bx-pencil.svg')" class="mr-2 pointer" />
            </td>
          </template>
        </Table>

        <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
          @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

        <ModalZones :modal_title="'Zonas'" :page_title="page_title"
            :dialog="dialog_zones" @updateDialog="updateDialogZones" :logistic_id="logistic_id"
            :logistic_user_id="logistic_user_id" />

        <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages" @next="loadPage(service)"
          @prev="loadPage(service)" @first="loadPage(service)" @update:modelValue="loadPage(service)"></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import Modal from "./Modal.vue";
import ModalZones from "./ModalZones.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { logisticsService } from "@/libs/ws/Super/logisticsService";

export default {
  name: "Logisticas",
  components: { Table, Modal, ModalZones },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: logisticsService,
      loading: false,
      header: [{ name: "Nombre", value: "name", align: "left" }, { name: "Razon Social", value: "business_name", align: "left" }, { name: "CUIT", value: "cuit", align: "left" }, { name: "Telefono", value: "phone", align: "left" }, { name: "Direccion", value: "address", align: "left" }, {name: "Max. Paquetes", value: "max_packages", align: "left"}, {name: "Habilitado", value: "enable", align: "left"}, { name: "Acción", align: "center" }],
      modal_title: "Logistica",
      button_name: "Crear",
      logistic_user_id: 0,
      logistic_id: 0
    };
  },
  watch: {
    searchQuery() {
      this.filterSearch();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "LOGISTICAS" }] });

    this.loadList(this.service);
  },
  methods: {
    updateDialogZones() {
      this.dialog_zones = false;
      this.logistic_user_id = 0;
      this.logistic_id = 0;
    },
    viewZones(item) {
      this.dialog_zones = true;
      this.logistic_id = item.id;
      this.logistic_user_id = item.user.id;
    },
  },
};
</script>
