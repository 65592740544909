<template>

    <v-layout class="container-modal">
        <v-dialog :modelValue="dialog" @click:outside="closeModal()" max-width="800px"
            transition="dialog-bottom-transition">

            <v-card>
                <v-form>
                    <v-card-title class="p-3 header-modal mb-4">
                        <span class="headline text-white">{{ modal_title }}</span>
                    </v-card-title>


                    <v-card-text>
                        <div class="container-card-fee">
                            <div class="content-card-fee">
                                <div v-if="loading">
                                    <v-skeleton-loader v-for="n in 3" :key="n" class="skeleton-card-loader mb-2"
                                        type="card"></v-skeleton-loader>

                                </div>
                                <div v-else>
                                    <template
                                        v-if="listZones.some(zoneGroup => zoneGroup.price != null && zoneGroup.zones.length > 0)">

                                        <div v-for="(zoneGroup) in listZones" :key="zoneGroup.id">
                                            <CardFee :zoneGroup="zoneGroup" :cardTitle="zoneGroup.zone_group.name"
                                                :basePrice="zoneGroup.zone_group.base_price" :isView="true"
                                                :isConfigure="true"></CardFee>
                                        </div>
                                    </template>
                                    <div v-else class="d-flex justify-content-center">
                                        <v-label class="text-component">La logística no configuró sus zonas</v-label>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <Button flat class="btn text-capitalize text-white me-1 btn-modal" type="button" rounded="xl"
                            height="50" text @click="closeModal()" label="Cerrar" color="error"></Button>


                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Button from "@/components/Button.vue";
import { ratesService } from "@/libs/ws/Logistic/ratesService";
import CardFee from "@/components/CardFee.vue";

export default {
    mixins: [ValidMixin],
    components: { Button, CardFee },
    props: {
        service: Object,
        modal_title: String,
        page_title: String,
        formBtn: String,
        dialog: Boolean,
        updateDialog: Function,
        loadList: Function,
        editItem: Object,
        logistic_user_id: Number,
        logistic_id: Number
    },
    data() {
        return {
            show: false,
            disabled: false,
            loading: false,
            name: "",
            listZones: [],
            provincias: [],
            defaultItem: null,
        };
    },
    watch: {
        dialog() {
            if (this.dialog) {
                this.loadZones();
            }
        },
    },
    mounted() {
    },
    methods: {

        closeModal() {
            this.$emit("updateDialog", false);
            this.listZones = [];
        },
        loadZones() {
            this.loading = true;
            const param = {
                user_owner_id: this.logistic_user_id,
                logistic_id: this.logistic_id,
                list: "logistic"
            }

            ratesService
                .filter(param)
                .then((response) => {
                    this.listZones = response.result.list;
                })
                .catch((e) => {
                    console.log('e', e)
                })
                .finally(() => {
                    this.loading = false;
                });
        }

    },
};
</script>