import * as Vue from "vue";
import * as VueRouter from "vue-router";
import VueCryptojs from "vue-cryptojs";
import vuetify from "./plugins/vuetify";
import veevalidate from "./plugins/veevalidate";
import Vue3Toastify, { toast } from "vue3-toastify";
import vue3GoogleLogin from "vue3-google-login";
import { authService } from "@/libs/ws/Public/authService";
import routes from "./routes";
import store from "./store";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueGoogleMaps from "@fawmi/vue-google-maps";

//Components Globales
import App from "./App.vue";
import FontAwesomeIcon from "./plugins/fontawesome";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogLoading from "@/components/DialogLoading.vue";
import DialogYesNo from "@/components/DialogYesNo.vue";
//Stepper va ser reemplazado por vuetify cuando incorpore el stepper
import { Steps } from "ant-design-vue";
import VueApexCharts from "vue3-apexcharts";
import "ant-design-vue/dist/antd.css";

require("@/assets/css/style.scss");
import "vue3-toastify/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
  mode: "history"
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authService.isAuthenticated()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    if (!authService.isAuthenticated() && to.path == "/") {
      next({
        path: "/login",
      });
    }
    if (authService.isAuthenticated() && to.path == "/login") {
      next({
        path: "/",
      });
    } else {
      next(); // make sure to always call next()!
    }
  }
});

const app = Vue.createApp(App);

app.config.globalProperties.toast = toast;

app.use(VueReCaptcha, {
  siteKey: "6LdGFO0nAAAAAGWqkbwahMJmMP7795gUpEzZvQ70",
  loaderOptions: {
    autoHideBadge: true,
  },
});
app.use(router);
app.use(vuetify);
app.use(veevalidate);
app.use(store);
app.use(VueCryptojs);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBZxqX4N1D48xhiYscozVya07W9haBj0ro",
  },
});
app.use(vue3GoogleLogin, {
  clientId: "432443017748-j9iqp86j4a3oru2igjpe14sbu8plk50m.apps.googleusercontent.com",
});
app.use(Vue3Toastify, {
  position: toast.POSITION.BOTTOM_RIGHT,
  theme: "dark",
});
app.use(Steps);
app.use(VueApexCharts);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("Title", Title);
app.component("DialogDelete", DialogDelete);
app.component("DialogLoading", DialogLoading);
app.component("DialogYesNo", DialogYesNo);
app.mount("#app");
