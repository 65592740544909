<template>
  <v-layout class="container-modal">
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="1100px"
      transition="dialog-bottom-transition">
      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title class="p-3 header-modal mb-4">
            <span class="headline text-white">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-6">
                <v-label>Nombre*</v-label>
                <v-text-field v-model="item.name" density="compact" persistent-hint variant="outlined"
                  :disabled="disabled"></v-text-field>

                <v-label>Razon social*</v-label>
                <v-text-field v-model="item.business_name" :disabled="disabled" density="compact"
                  :rules="[rules.required]" persistent-hint variant="outlined"></v-text-field>

                  <v-label>Email*</v-label>
                <v-text-field v-model="item.email" density="compact" persistent-hint variant="outlined"
                  :disabled="disabled" :rules="[rules.required]"></v-text-field>

                <v-label>Cuit*</v-label>
                <v-text-field v-model="item.cuit" :disabled="disabled" density="compact" :rules="[rules.required]"
                  persistent-hint variant="outlined"></v-text-field>

                <v-label>Telefono*</v-label>
                <v-text-field v-model="item.phone" density="compact" persistent-hint variant="outlined"
                  :disabled="disabled" :rules="[rules.required]"></v-text-field>

                <v-label>Provincia</v-label>
                <v-combobox density="compact" :rules="[rules.requiredComboBox]" persistent-hint item-title="name"
                  item-value="id" v-model="item.provincia" :items="provincias" variant="outlined" autocomplete="off"
                  @update:modelValue="loadPostalCodes(item.provincia.id)"></v-combobox>

                <v-label>Código postal</v-label>
                <v-combobox v-model="item.postal_code" density="compact" variant="outlined" item-title="code"
                  item-value="id" :items="postalCodes" autocomplete="off" :disabled="disabled"
                  :rules="[rules.requiredComboBox]" clearable :loading="loadingPostalCode"
                  @update:modelValue="loadPregeo()"></v-combobox>

                  <v-label>Localidad*</v-label>
                  <v-text-field v-model="item.city" density="compact" persistent-hint variant="outlined"
                    :disabled="disabled" :rules="[rules.required]"></v-text-field>
                </div>
                <div class="col-md-6">
                  


                <v-label>Dirección</v-label>
                <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined"
                  v-model="item.address" :error-messages="errorAddress ? [errorAddress] : []" @change="loadPregeo"
                  :loading="loadingAddress"></v-text-field>

                <v-label>Referencia/Piso/Departamento</v-label>
                <v-text-field v-model="item.reference" density="compact" persistent-hint variant="outlined"
                  :disabled="disabled" :rules="[rules.required]"></v-text-field>

                <v-row class="mb-4">
                  <v-col cols="6">
                    <v-label>Latitud</v-label>
                    <v-text-field v-model="item.lat" :disabled="disabled" density="compact"
                      :rules="[rules.required, rules.float, rules.latitud]" persistent-hint
                      variant="outlined"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-label>Longitud</v-label>
                    <v-text-field v-model="item.lng" :disabled="disabled" density="compact"
                      :rules="[rules.required, rules.float, rules.longitud]" persistent-hint
                      variant="outlined"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pt-3">
                    <Maps :lat="item.lat" :lng="item.lng"></Maps>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-checkbox density="compact" label="Habilitado" :disabled="disabled"
                      v-model="item.user.enable"></v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <Button flat class="btn text-capitalize text-white me-1 btn-modal" type="button" rounded="xl" height="50"
              text @click="$emit('updateDialog', false)" label="Cerrar" color="error"></Button>

            <Button flat :loading="loading" text type="submit"
              class="bg-button-register btn text-capitalize text-white btn-modal" rounded="xl" height="50"
              :label="formBtn" v-if="!disabled"></Button>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Button from "@/components/Button.vue";
import Maps from "@/components/Maps.vue";
import { postalCodesService } from "@/libs/ws/Public/postalCodesService";
import { shippingService } from "@/libs/ws/Seller/shippingService";

export default {
  mixins: [ValidMixin],
  components: { Button, Maps },
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      loading: false,
      name: "",
      item: {
        id: 0,
        name: "",
        business_name: "",
        cuit: "",
        phone: "",
        address: "",
        city: "",
        reference: "",
        email: "",
        lng: null,
        lat: null,
        provincia: null,
        postal_code: null,
        enable: false
      },
      typesVehicle: [],
      defaultItem: null,
      loadingPostalCode: false,
      loadingAddress: false,
      errorAddress: false,

    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    loadPregeo() {

      if (this.item.address) {
        this.loadingAddress = true;
        this.errorAddress = null;

        const address = {
          address: this.item.address + ', ' + this.item.city + ', ' + this.item.postal_code.code + ', ' + this.item.provincia.name
        }

        shippingService
          .pregeo(address)
          .then((response) => {
            this.item.lat = response.result.lat;
            this.item.lng = response.result.lng;
            if (response.status === false) {
              this.errorAddress = response.msg;
            }
          })
          .catch((response) => {
            this.toast.error(response.msg)
          })
          .finally(() => {
            this.loadingAddress = false;
          });
      }
    },
    loadPostalCodes(id_provincia, postalCodeNull = true) {
      if (postalCodeNull) {
        this.item.postal_code = null;
      }
      this.loadingPostalCode = true;

      const body = {
        provincia: {
          id: id_provincia
        }
      }

      postalCodesService
        .getPostalCodes(body)
        .then((response) => {
          this.postalCodes = response.result.list;
        })
        .catch((e) => {
          console.log("service postal codes error");
        })
        .finally(() => {
          this.loadingPostalCode = false;
        });

    },
    createItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;

        var data = {...this.item};
        delete data.user;

        if (this.item.id > 0) {
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se modificó correctamente.");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se creó correctamente");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
