<template>
    <div class="skeleton-top-seller" v-if="loading">
        <v-skeleton-loader
        class="skeleton-card-top-seller"
        type="card"
        ></v-skeleton-loader>
    </div>
    <v-card class="custom-card-top-sellers" v-else> 
        <v-card-text>
            <div class="title-section">
                <div>
                    <v-label class="title mb-0">{{ title }}</v-label>
                </div>

                <v-label class="subtitle">{{ subtitle }}</v-label>
            </div>
            <v-row v-for="(seller, index) in sellers" :key="index" class="seller-row mb-0 mt-0">
                <v-col cols="1" class="bar-container pb-0">
                    <div class="bar"></div>
                </v-col>
                <v-col cols="11" class="pb-0 ps-0">
                    <div class="seller-info">
                        <div>
                            <v-label class="seller-name">{{ seller.seller_name }}</v-label>
                        </div>
                        <v-label class="seller-shippings">{{ seller.processed_shippings }} envíos</v-label>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'TopVendedores',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Top 5 vendedores'
        },
        subtitle: {
            type: String,
            default: 'Mensual'
        },
        sellers: {
            type: Array,
            required: true
        }
    }
};
</script>