<template>
  <div class="container-filters mb-4">
    <v-row dense align="center">
      <v-col :cols="12" :md="3" class="pb-0 pt-0" v-if="!mainFilters.shipping_code && !mainFilters.external_reference">
        <v-menu v-model="fromMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ props }">
            <v-label>Desde</v-label>
            <v-text-field v-bind="props" v-model="fromDateFormatted" prepend-icon="mdi-calendar" readonly density="compact" variant="outlined" persistent-hint></v-text-field>
          </template>
          <v-date-picker v-model="mainFilters.from_date" @update:modelValue="closeFromMenu" hide-header></v-date-picker>
        </v-menu>
      </v-col>

      <v-col :cols="12" :md="3" class="pb-0 pt-0" v-if="!mainFilters.shipping_code && !mainFilters.external_reference">
        <v-menu v-model="toMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ props }">
            <v-label>Hasta</v-label>
            <v-text-field v-bind="props" v-model="toDateFormatted" prepend-icon="mdi-calendar" readonly density="compact" variant="outlined" persistent-hint></v-text-field>
          </template>
          <v-date-picker v-model="mainFilters.to_date" @update:modelValue="closeToMenu" hide-header></v-date-picker>
        </v-menu>
      </v-col>

      <v-col :cols="12" :md="3" class="pb-0 pt-0">
        <v-label>Código de seguimiento</v-label>
        <v-text-field density="compact" persistent-hint variant="outlined"
          v-model="mainFilters.shipping_code"></v-text-field>
      </v-col>

      <v-col :cols="12" :md="3" class="pb-0 pt-0" v-if="shippingAll">
        <v-label>Código de orden</v-label>
        <v-text-field density="compact" persistent-hint variant="outlined"
          v-model="mainFilters.order_code"></v-text-field>
      </v-col>

      <v-col :cols="12" :md="3" class="pb-0 pt-0" v-if="!shippingAll">
        <v-label>Identificador externo</v-label>
        <v-text-field density="compact" persistent-hint variant="outlined"
          v-model="mainFilters.external_reference"></v-text-field>
      </v-col>

      <v-col :cols="12" :md="3" class="pb-0 pt-0" v-if="shippingAll">
        <v-label>Vendedor</v-label>
        <v-combobox density="compact" persistent-hint item-title="name" item-value="id" :items="sellers"
          variant="outlined" autocomplete="off" v-model="mainFilters.seller"></v-combobox>
      </v-col>

      <v-col :cols="12" :md="3" class="pb-0 pt-0" v-if="assigned && shippingAll">
        <v-label>Estado</v-label>
        <v-combobox density="compact" persistent-hint item-title="name" item-value="id" :items="states"
          variant="outlined" autocomplete="off" v-model="mainFilters.state"></v-combobox>
      </v-col>

      <v-col :cols="12" :md="(!shippingAll || (!assigned && !shippingAll)) ? 12 : 6" class="d-flex justify-content-end align-items-end">
        <Button type="submit" class="btn-filter bg-button-register btn text-capitalize w-100 text-white" rounded="xl"
          height="40" label="Buscar" @click="filter()"></Button>
      </v-col>
    </v-row>
  </div>
</template>



<script>
import Button from '@/components/Button.vue';
import { sellersService } from '@/libs/ws/Super/sellersService';
import ConstantUserType from "@/constants/user_type";

export default {
  components: {
    Button,
  },
  props: {
    loadList: Function,
    assigned: Boolean,
    service: Object,
    shippingAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fromMenu: false,
      toMenu: false,
      states: [],
      sellers: [],
      mainFilters: {
        from_date: new Date(),
        to_date: new Date(),
        state: null,
        shipping_code: "",
        order_code: "",
        external_reference: ""
      },

    };
  },
  computed: {
    fromDateFormatted() {
      return this.mainFilters.from_date.toISOString().slice(0, 10);
    },
    toDateFormatted() {
      return this.mainFilters.to_date.toISOString().slice(0, 10);
    },
  },
  mounted() {
    this.loadStates();

    if (localStorage.getItem("user_type") == ConstantUserType.SUPERUSER) {
      this.loadSellers();
    }
  },
  methods: {
    loadStates() {
      this.service
        .states()
        .then((response) => {
          this.states = response.result
        })
        .catch((e) => {
          console.log("service.load states shippings error");
          console.log(e)
        })
    },
    loadSellers() {
      sellersService
        .filter()
        .then((response) => {
          if (response.status) {
            this.sellers = response.result.list
          }
        })
        .catch((e) => {
          console.log("e error load sellers", e)
        })
        .finally(() => {

        })
    },
    getFilterParams() {
      var params = {};

      if (this.mainFilters.from_date != "" && this.mainFilters.from_date) {
        params['from_date'] = this.mainFilters.from_date.toISOString().slice(0, 10);
      }
      if (this.mainFilters.to_date != "" && this.mainFilters.to_date) {
        params['to_date'] = this.mainFilters.to_date.toISOString().slice(0, 10);
      }

      if (this.mainFilters.shipping_code != "" && this.mainFilters.shipping_code) {
        params['shipping_code'] = this.mainFilters.shipping_code;
        delete params['to_date'];
        delete params['from_date'];
      }

      if (this.mainFilters.state != "" && this.mainFilters.state) {
        params['state'] = this.mainFilters.state;
      }

      if (this.shippingAll) {
        if (this.mainFilters.order_code != "" && this.mainFilters.order_code) {
          params['order_code'] = this.mainFilters.order_code;
          delete params['to_date'];
          delete params['from_date'];
        }

        if (this.mainFilters.seller != "" && this.mainFilters.seller) {
          params['seller_user'] = this.mainFilters.seller
        }
      }

      if (!this.shippingAll) {
        if (this.mainFilters.external_reference != "" && this.mainFilters.external_reference) {
          params['external_reference'] = this.mainFilters.external_reference;
          delete params['to_date'];
          delete params['from_date'];
        }

     
        if (this.assigned) {
          params["no_order"] = 0;
        } else {
          params["no_order"] = 1;
        }
      }
      return params;
    },
    filter() {
      const params = this.getFilterParams();
      this.$emit('loadList', this.service, params);
    },
    closeFromMenu() {
      this.fromMenu = false;
    },
    closeToMenu() {
      this.toMenu = false;
    },
  },
};
</script>