<template>

  <div v-if="step===2" transition="fade-transition">
    <div class="d-flex mb-4">
      <v-avatar color="surface-variant" size="80"></v-avatar>
      <v-label class="text-general text-uppercase ms-3 mb-0">
        {{ selectedQuotation.logistic != null ? selectedQuotation.logistic.name : selectedQuotation.idriver != null ? selectedQuotation.idriver.name : "" }}
      </v-label>
    </div>

    <Table :list="quotations_details" :header="header" :loading="loading_quotation_detail">
      <template v-slot:rows="{ item }">

        <td class="table-cell">{{ item.shipping.creation_date }}</td>
        <td class="table-cell">{{ item.shipping.code }}</td>
        <td class="table-cell">{{ item.shipping.destination_address }} - {{ item.shipping.destination_location }}</td>
        <td class="table-cell">{{ item.rate.price }}</td>

      </template>
    </Table>

    <v-pagination
        v-model="current_page"
        :length="totalPages"
        @input="updatePage"
        @next="updatePage"
        @prev="updatePage"
        @first="updatePage"
        @update:modelValue="updatePage"
      ></v-pagination>
      
    <div class="w-100 d-flex justify-content-end text-end">
      <div>
        <v-label class="text-total">Total <span class="ms-1 text-total-price"> ${{ total_price }}</span></v-label>
        <br/>
        <v-label class="subtext-order">Luego de cotizar, la empresa de transporte o conductor tendrán 10 minutos para aceptar la cotización.</v-label>
      </div>
    </div>
  </div>
</template>


<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Table from "@/components/Table.vue";
import { ordersService } from "@/libs/ws/Seller/ordersService";

export default {
  mixins: [ValidMixin],
  components: {Table},
  props: {
    loading_quotation_detail: Boolean,
    quotations_details: Array,
    step: Number,
    total_details: Number,
    items_per_page: Number,
    total_price: Number,
    selectedQuotation:Object
  },
  data() {
    return {
      show: false,
      service: ordersService,
      disabled: false,
      loading: false,
      header: [
        { name: "Fecha", value: "shipping.creation_date", align: "left" },
        { name: "Codigo de seguimiento", value: "shipping.code", align: "left" },
        { name: "Direccion", value: "shipping.destination_address", align: "left" },
        { name: "Precio", value: "rate.price", align: "left" },
      ],
      list: [],

    };
  },

  mounted() {
  },
  methods: {
    updatePage(page) {
      this.$emit('updatePage', page); // Emitir evento al cambiar de página
    },
  },
  computed: {
    totalPrice() {
      return this.quotations_details.reduce((total, item) => total + parseFloat(item.rate.price), 0);
    },
    totalPages() {
      return Math.ceil(this.total_details / this.items_per_page);
    }
  }
};
</script>