<template>
  <div class="container-list">
    <v-container fluid>
      <div>
        <v-row class="mb-3">
          <v-col md="6">

          </v-col>
          <v-col md="6">
            <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
              label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
              @input="filterSearch"></v-text-field>
          </v-col>
        </v-row>

        <Filters @loadList="loadList" :service="service"/>

        <Table :list="list" :header="header" :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy"
          :sortDesc="sortDesc">
          <template v-slot:rows="{ item }">
            <td class="table-cell">{{ item.creation_date }}</td>
            <td class="table-cell">{{ item.seller_order.seller_user.role.name }}</td>
            <td class="table-cell">{{ item.seller_order.shippings.length }}</td>
            <td class="table-cell">{{ item.state.name }}</td>
            <td class="table-cell">{{ item.seller_order.sub_state ? item.seller_order.sub_state.name : "-" }}</td>
            <td class="table-cell">{{ item.expiring_datetime }}</td>
            <td class="table-cell">{{ item.price_agreed }}</td>

            <td class="table-cell text-center">
              <v-tooltip location="bottom" text="Detalle de orden">
                <template v-slot:activator="{ props }">
                    <img v-bind="props" @click="viewItemPage(item)" :src="require('@/assets/images/view.svg')" class="mr-2 pointer" />

                </template>
              </v-tooltip>

              <v-tooltip location="bottom" text="Aceptar" v-if="isViewAcceptCancelOrder(item)">
                <template v-slot:activator="{ props }">
                    <img v-bind="props" @click="handleDialogAccept(item)" :src="require('@/assets/images/accept.svg')" class="mr-2 pointer" />

                </template>
              </v-tooltip>

              <v-tooltip location="bottom" text="Cancelar" v-if="isViewAcceptCancelOrder(item)">
                <template v-slot:activator="{ props }">
                  <img v-bind="props" @click="handleDialogReject(item)" :src="require('@/assets/images/reject.svg')" class="mr-2 pointer" />
                </template>
              </v-tooltip>

              </td>
          </template>
        </Table>

        <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
          @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

        <DialogYesNo :open="dialog_accept" title="Orden"
          message="¿Estas seguro que desea aceptar la orden?" @cancel="dialog_accept = false"
          @accept="handleAccept" nameButtonNo="Cancelar" nameButtonYes="Aceptar"  :loading="loading_accept"/>

        <DialogYesNo :open="dialog_reject" title="Orden"
          message="¿Estas seguro que desea rechazar la orden?" @cancel="dialog_reject = false"
          @accept="handleReject" nameButtonNo="Cancelar" nameButtonYes="Rechazar" :loading="loading_reject"/>

        <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages" @next="loadPage(service)"
          @prev="loadPage(service)" @first="loadPage(service)" @update:modelValue="loadPage(service)"></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { ordersQuotingService } from "@/libs/ws/Logistic/ordersQuotingService";
import OrderQuotingsState from "@/constants/order_quotings_state";
import Filters from "./Filters.vue";
import DialogYesNo from "@/components/DialogYesNo.vue";
import OrderState from "@/constants/order_state";

export default {
  name: "Orders",
  components: { Table,Filters,DialogYesNo},
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: ordersQuotingService,
      loading: false,
      header: [
        { name: "Fecha de creacion", value: "creation_date", align: "left" },
        { name: "Vendedor", value: "seller_order.seller_user.role.name", align: "left" },
        { name: "Cantidad de envios", value: "", align: "left" },
        { name: "Estado", value: "state.name", align: "left" },
        { name: "Sub Estado", value: "seller_order.sub_state.name", align: "left" },
        { name: "Vencimiento", value: "expiring_datetime", align: "left" },
        { name: "Total", value: "price_agreed", align: "left" },
        { name: "Acción", align: "center" },
      ],
      modal_title: "Orden",
      button_name: "Crear",
      params: {
        from_date: new Date().toISOString().slice(0, 10),
        to_date: new Date().toISOString().slice(0, 10)
      },
      dialog_quotation: false,
      order_quoting_id: null,
      dialog_accept: false,
      dialog_reject: false,
      loading_accept: false,
      loading_reject: false,
      
    };
  },
  watch: {
    searchQuery() {
      this.filterSearch();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: 'COTIZACIONES ' }] });

    this.loadList(this.service, this.params);
  },
  methods: {
    isViewAcceptCancelOrder(item) {
      console.log("item", item);
      if(item.state.id === OrderQuotingsState.STATE_PENDING && 
      item.seller_order.state.id === OrderState.STATE_QUOTING && 
      item.seller_order.sub_state.id === OrderState.SUBSTATE_PENDIENTE_ACEPTACION
    ){
        return true;
      }
      return false;
    },
    handleDialogAccept(item){
      this.order_quoting_id = item.id;
      this.dialog_accept = true;
    },
    handleDialogReject(item){
      this.order_quoting_id = item.id;
      this.dialog_reject = true;
    },
    viewItemPage(item) {
      this.$router.push(`/orders-quotings/${item.seller_order.code}`);
    },
    handleAccept() {
      this.loading_accept = true;
      const params = {
        order_quoting_id: this.order_quoting_id
      }

      ordersQuotingService
        .accept(params)
        .then(response => {
          if (response.status) {
            this.toast.success("La " + this.modal_title + " se acepto correctamente.");
            this.loadList(this.service, this.params);
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((response) => {
          this.toast.error(response.msg);
        })
        .finally(() => {
          this.loading_accept = false;
          this.dialog_accept = false;
          this.order_quoting_id = null;
        });
    },
    handleReject(){
      this.loading_reject = true;
      const params = {
        order_quoting_id: this.order_quoting_id
      }

      ordersQuotingService
        .reject(params)
        .then(response => {
          if (response.status) {
            this.toast.success("La " + this.modal_title + " se rechazo correctamente.");
            this.loadList(this.service, this.params);
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((response) => {
          this.toast.error(response.msg);
        })
        .finally(() => {
          this.loading_reject = false;
          this.dialog_reject = false;
          this.order_quoting_id = null
        });  
    }
  },
};
</script>
