<template>

  <v-container v-if="loading_quotation">
    <v-row>
      <v-col
        v-for="index in 8"
        :key="index"
        cols="3"
        md="3"
      >
        <v-skeleton-loader class="mx-auto border" max-width="225" max-height="185" type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
  <v-row v-else>
    <v-col cols="3" class="container-card-fee" v-for="(quotation, index) in quotations" :key="index"
      @click="$emit('handleSelectQuotation',quotation)">
      <div class="content-card-order">
        <v-card class="custom-card"
          :class="{ 'disabled-card': selectedQuotation !== quotation, 'enabled-card': selectedQuotation === quotation }">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-content-center">
                  <v-avatar color="surface-variant" size="80"></v-avatar>
                </div>
                <div class="d-flex justify-content-center">
                  <v-label class="title-card-fee mt-3 mb-2">{{ quotation.logistic != null ? quotation.logistic.name : quotation.idriver != null ? quotation.idriver.name : "" }}</v-label>
                </div>
                <div class="d-flex justify-content-center mb-0">
                  <v-label class="title-card-fee">$ {{ quotation.price }}</v-label>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-col>
    </v-row>
    <v-pagination
        v-model="current_page"
        :length="totalPages"
        @input="updatePage"
        @next="updatePage"
        @prev="updatePage"
        @first="updatePage"
        @update:modelValue="updatePage"
      ></v-pagination>
</template>


<script>
import ValidMixin from "@/mixins/ValidMixin.vue";

export default {
  mixins: [ValidMixin],
  components: { },
  props: {
    loading_quotation:Boolean,
    quotations: Array,
    handleSelectQuotation: Function,
    selectedQuotation: String,
    total_quotations: Number,
    items_per_page: Number
  },
  data() {
    return {
      show: false,
      disabled: false,
      loading: false,
      name: "",
      current_page: 1
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total_quotations / this.items_per_page);
    },
  },

  mounted() {
  },
  methods: {
    updatePage(page) {
      this.$emit('updatePage', page); 
    },
  },
};
</script>