<template>
  <div class="container-filters mb-4">
    <v-row dense align="center">
      <v-col :cols="12" :md="3" class="pb-0 pt-0">
        <v-menu v-model="fromMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ props }">
            <v-label>Desde</v-label>
            <v-text-field v-bind="props" v-model="fromDateFormatted" prepend-icon="mdi-calendar" readonly density="compact" variant="outlined" persistent-hint></v-text-field>
          </template>
          <v-date-picker v-model="mainFilters.from_date" @update:modelValue="selectFromDate" hide-header></v-date-picker>
        </v-menu>
      </v-col>

      <v-col :cols="12" :md="3" class="pb-0 pt-0">
        <v-menu v-model="toMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ props }">
            <v-label>Hasta</v-label>
            <v-text-field v-bind="props" v-model="toDateFormatted" prepend-icon="mdi-calendar" readonly density="compact" variant="outlined" persistent-hint></v-text-field>
          </template>
          <v-date-picker v-model="mainFilters.to_date" @update:modelValue="selectToDate" hide-header></v-date-picker>
        </v-menu>
      </v-col>

      <v-col :cols="12" :md="6" class="d-flex justify-content-end align-items-end">
        <Button type="submit" class="btn-filter bg-button-register btn text-capitalize w-100 text-white" rounded="xl" height="40" label="Buscar" @click="filter" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    loadList: Function,
    service: Object,
  },
  data() {
    return {
      fromMenu: false,
      toMenu: false,
      mainFilters: {
        from_date: new Date(),
        to_date: new Date(),
      },
    };
  },
  computed: {
    fromDateFormatted() {
      return this.mainFilters.from_date.toISOString().slice(0, 10);
    },
    toDateFormatted() {
      return this.mainFilters.to_date.toISOString().slice(0, 10);
    },
  },
  methods: {
    getFilterParams() {
      let params = {};
      if (this.mainFilters.from_date) {
        params['from_date'] = this.mainFilters.from_date.toISOString().slice(0, 10);
      }
      if (this.mainFilters.to_date) {
        params['to_date'] = this.mainFilters.to_date.toISOString().slice(0, 10);
      }
      return params;
    },
    filter() {
      const params = this.getFilterParams();
      this.$emit('loadList', this.service, params);
    },
    selectFromDate() {
      this.fromMenu = false;
    },
    selectToDate() {
      this.toMenu = false;
    },
  },
};
</script>
