<template>
  <div class="container-list">
    <v-container fluid>
      <div>
        <v-row class="mb-3">
          <v-col md="6">
            <Modal button_name="Agregar Colaborador" :modal_title="modal_title" :page_title="page_title"
              :formBtn="formBtn" :dialog="dialog" :service="service" @updateDialog="updateDialog"
              @loadList="loadList(service)" :editItem="mainItem" />
          </v-col>
          <v-col md="6">
            <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
              label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
              @input="filterSearch"></v-text-field>
          </v-col>
        </v-row>

        <Table :list="list" :header="header" :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy"
          :sortDesc="sortDesc">
          <template v-slot:rows="{ item }">
            <td class="table-cell">{{ item.name }}</td>
            <td class="table-cell">{{ item.user.role.name }}</td>
            <td class="table-cell">{{ item.user.email }}</td>

            <td class="table-cell text-center">
              <v-tooltip location="bottom" text="Editar">
                <template v-slot:activator="{ props }">
                  <img v-bind="props" @click="editItem(item)" :src="require('@/assets/images/bx-pencil.svg')" class="mr-2 pointer" />
                </template>
              </v-tooltip>
              <v-tooltip location="bottom" text="Eliminar">
                <template v-slot:activator="{ props }">
                  <img v-bind="props" @click="deleteItem(item)" :src="require('@/assets/images/bx-trash.svg')" class="ml-2 pointer" />
                </template>
              </v-tooltip>
            </td>
          </template>
        </Table>

        <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
          @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

        <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages" @next="loadPage(service)"
          @prev="loadPage(service)" @first="loadPage(service)" @update:modelValue="loadPage(service)"></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import Modal from "./Modal.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { employeesService } from "@/libs/ws/Logistic/employeesService";

export default {
  name: "Employees",
  components: { Table, Modal },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: employeesService,
      loading: false,
      header: [
        { name: "Nombre", value: "name", align: "left" },
        { name: "Rol", value: "user.role.name", align: "left" },
        { name: "Email", value: "user.email", align: "left" },
        { name: "Acción", align: "center" },
      ],
      modal_title: "Colaborador",
      button_name: "Crear",
    };
  },
  watch: {
    searchQuery() {
      this.filterSearch();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "COLABORADORES" }] });

    this.loadList(this.service);
  },
  methods: {},
};
</script>
